import { IconType } from "../views/dashboard/build_section/navigation/__generated__/TabsCard2_brand.graphql";
import { IconName } from "../views/dashboard/build_section/navigation/TabsCard2";

export const convertToIconName = (icon: IconType): IconName => {
  return icon
    .split("_")
    .map((word, index) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("") as IconName;
};
