const ForYouIcon = ({
  color = "currentColor",
  size = 24,
  className,
}: {
  color?: string;
  size?: number;
  className?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="2 2 24 24"
      fill={color}
      className={className}
    >
      <g clip-path="url(#clip0_2207_36311)">
        <path d="M6.73633 24.6758H20.9511C23.4121 24.6758 24.6308 23.457 24.6308 21.0429V6.73438C24.6308 4.32031 23.4121 3.10156 20.9511 3.10156H6.73633C4.28711 3.10156 3.05664 4.30859 3.05664 6.73438V21.0429C3.05664 23.4687 4.28711 24.6758 6.73633 24.6758ZM6.75976 22.789C5.58789 22.789 4.94336 22.1679 4.94336 20.9492V6.82812C4.94336 5.60938 5.58789 4.98828 6.75976 4.98828H20.9277C22.0878 4.98828 22.7441 5.60938 22.7441 6.82812V20.9492C22.7441 22.1679 22.0878 22.789 20.9277 22.789H6.75976Z" />
        <path d="M8.95117 6.95703C7.99023 6.95703 7.27539 7.70703 7.27539 8.73828C7.27539 10.2617 8.91602 11.5977 10.0762 12.4062C10.1934 12.4883 10.3106 12.5469 10.3809 12.5469C10.4629 12.5469 10.5566 12.4766 10.6621 12.4062C11.8457 11.6797 13.4511 10.2617 13.4511 8.73828C13.4511 7.70703 12.7363 6.95703 11.752 6.95703C11.1543 6.95703 10.6504 7.32031 10.3691 7.85937C10.0762 7.32031 9.5957 6.95703 8.95117 6.95703ZM8.01367 16.2148H19.5566C19.9668 16.2148 20.2832 15.8984 20.2832 15.4883C20.2832 15.0898 19.9668 14.7734 19.5566 14.7734H8.01367C7.5918 14.7734 7.27539 15.0898 7.27539 15.4883C7.27539 15.8984 7.5918 16.2148 8.01367 16.2148ZM8.01367 20.2226H16.4746C16.8847 20.2226 17.2011 19.9062 17.2011 19.5078C17.2011 19.0976 16.8847 18.7812 16.4746 18.7812H8.01367C7.5918 18.7812 7.27539 19.0976 7.27539 19.5078C7.27539 19.9062 7.5918 20.2226 8.01367 20.2226Z" />
      </g>
      <defs>
        <clipPath id="clip0_2207_36311">
          <rect
            width="21.5742"
            height="21.5977"
            transform="translate(3.05664 3.07812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForYouIcon;
